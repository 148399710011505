import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { MapComponent } from '../components/MapComponent'

interface mapPageProps {
  data: {
    allWpPage: {
      edges: {
        node: {
          id: string
          title: string
          parentDatabaseId: number
          databaseId: number
          dateArrived: string
          dateLeft: string
          gPS: string
          content: string
          slug: string
        }
      }[]
    }
  }
}

interface towns {
  node: {
    id: string
    title: string
    parentDatabaseId: number
    databaseId: number
    dateArrived: string
    dateLeft: string
    gPS: string
    content: string
    slug: string
  }
  countryLink: string
}

const AllMapPage: FunctionComponent<mapPageProps> = (props) => {
  const root = props.data.allWpPage.edges.find((el) => el.node.slug === 'root')
    ?.node.databaseId
  const years = props.data.allWpPage.edges.filter(
    (el) => el.node.parentDatabaseId === root
  )
  const allTowns: towns[] = []
  years.forEach((element) => {
    const yearPath = `/${element.node.title}`
    const countries = props.data.allWpPage.edges.filter(
      (el) => el.node.parentDatabaseId === element.node.databaseId
    )
    countries.forEach((elementInner) => {
      const countryPath = `${yearPath}/${elementInner.node.title}`
      const towns = props.data.allWpPage.edges.filter(
        (el) => el.node.parentDatabaseId === elementInner.node.databaseId
      )
      allTowns.push(
        ...towns.map((town) => ({
          ...town,
          countryLink: `/map${countryPath}`,
        }))
      )
    })
  })
  return (
    <Layout>
      <MapComponent
        data={allTowns}
        zoom={5}
        center={{
          lat: 46,
          lng: 3,
        }}
        single={false}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage {
      edges {
        node {
          id
          title
          parentDatabaseId
          databaseId
          dateArrived
          dateLeft
          gPS
          content
          slug
        }
      }
    }
  }
`

export default AllMapPage
